<template>
  <div class="form-group-wrap" ref="vertGroup">
    <h5 v-if="field.preLabel" class="p-3">{{ field.preLabel }}</h5>
    <button v-if="field.selectable" type="button" class="btn btn-secondary btn-sm mb-4" data-toggle="modal" data-target=".bd-example-modal-xl"><i class="fas fa-plus mr-2"></i><span>Add/Remove</span></button>
    <div :class="getHeadClass()" v-if="'vertical' != field.layout">
      <h4>{{ field.label }}</h4>
      <template v-for="(childField, childFieldName) in field.inputs">
          <div class="col" :key="childFieldName">{{ childField.label }}</div>
      </template>
    </div>
    <div v-else class="mt-4 mb-4 formulate-input-group-add-more">
      <h5>{{ field.label }}</h5>
      <button type="button" v-if="false !== field.editable" class="btn btn-secondary btn-sm float-right mb-4" @click.prevent="customAdd()"><i class="fas fa-plus mr-2"></i><span>Add Another</span></button>
    </div>

    <div v-if="field.selectable">
      <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Select Items</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="border-bottom-1 border-dark bg-light p-2 pt-3 sticky-filter input-group mb-2">
              <span class="input-group-prepend">
                  <div class="input-group-text bg-transparent border-right-0"><i class="fa fa-search"></i></div>
              </span>
              <input type="search" id="fl-filter" v-model="filter" placeholder="Search" class="form-control">
            </div>
            <table class="font-weight-bold table table-striped table-hover table-sm table-add-existing">
              <tr>
                <td v-for="valKey in field.selectable.fields" :key="valKey">
                  {{ valKey }}
                </td>
              </tr>
              <tr v-for="valObj, valIdx in values" :key="valIdx" v-show="selectFilterMatch(valObj)">
                  <td v-for="valKey, keyNum in field.selectable.fields" :key="valKey">
                    <a class="btn btn-sm btn-primary float-left ml-2" v-if="keyNum==0 && hideRow(valIdx)" @click.prevent="selectIndex(valIdx)">Add</a>
                    <a class="btn btn-sm btn-danger float-left ml-2" v-else-if="keyNum==0" @click.prevent="deselectIndex(valIdx, valObj, field.inputs)">Remove</a>
                    {{ valObj[valKey] }}
                  </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <FormulateInput
      type="group"
      :name="fieldName"
      :repeatable="field.editable"
      add-label="+ Add Another"
      :remove-label="getRemoveLabel(field)"
      validation="required"
      v-model="values"
      @repeatableRemoved="repeatableRemoved"
      @repeatableAdded="repeatableAdded"
    >
      <template #remove="{removeItem}" v-if="'vertical' == field.layout">
        <button @click.prevent="removeItem" class="vf-remove-btn d-none"></button>
      </template>
      <template #default="{index}">
      <div style="clear: both;" v-if="'vertical' == field.layout">
        <div class="card mt-2" v-bind:class="{ 'd-none': hideRow(index) }">
            <div class="card-header" v-if="field.label2">
              <button type="button" class="btn btn-danger btn-sm float-right" @click.prevent="customRemove(index, $event)"><i class="fas fa-trash-alt mr-2"></i><strong>Remove</strong></button>
              <h6 class="d-inline-block">{{ field.label2 }} <span v-if="false !== field.editable">#{{ index+1 }}</span></h6>
              <div class="btn-group d-inline-block ml-3" role="group" aria-label="Basic outlined example">
                <button type="button" class="btn btn-outline-dark btn-sm" v-bind:class="{ active: isToggleActive(fieldName, index, false) }" @click.prevent="toggleItem(fieldName, index, false);">Hide</button>
                <button type="button" class="btn btn-outline-dark btn-sm" v-bind:class="{ active: isToggleActive(fieldName, index, true) }" @click.prevent="toggleItem(fieldName, index, true);">Show</button>
              </div>
            </div>
            <div class="card-body" :ref="'group-'+fieldName+'-'+index">
              <template v-for="(childField, childFieldName) in field.inputs">
                <template v-if="'group' == childField.type">
                  <template v-if="'policy' == childField.parent">
                            <FormGroup :configs="configs" :allData="values" :repIndex="index" :model="values['PolicyData'][childFieldName+'s'][childFieldName]" :fieldName="childFieldName" :field="childField" @repeatableRemoved="repeatableRemoved" @onInputUpdate="saveValues" :key="childFieldName"/>
                          </template>
                          <template v-else>
                            <FormGroup :configs="configs" :allData="values" :repIndex="index" :model="values[childFieldName+'s'][childFieldName]" :fieldName="childFieldName" :field="childField" @onInputUpdate="saveValues" :key="childFieldName"/>
                          </template>
                </template>
                <h5 :key="childFieldName+1" v-if="getPrePostLabel(childFieldName)" class="p-3">{{ getPrePostLabel(childFieldName) }}</h5>
                <div class="row" :key="childFieldName">
                    <label class="col-sm-4 col-form-label">{{ childField.label }}</label>
                    <div class="col-sm-8">
                      <FormulateInput 
                        :type="getFieldType(childField.type)"
                        :name="childFieldName"
                        :options="getFieldOptions(childField)"
                        :validation="getFieldValidation(childField.validation)"
                        :validation-messages="getFieldValidationMessages(childField.validationMessages)"
                        :input-class="getInputClasses(childField)"
                        :wrapper-class="getWrapperClass(childField)"
                        :readonly="childField.readonly"
                        :placeholder="childField.placeholder"
                        @change="saveValues(childField, values[index][childFieldName])" />
                    </div>
                  </div>
              </template>
            </div>
        </div>
      </div>
      <div v-else class="row" v-bind:class="{ 'd-none': hideRow(index) }">
        <template v-for="(childField, childFieldName) in field.inputs">
            <template v-if="'group' == childField.type">
                  <template v-if="'policy' == childField.parent">
                    <FormGroup :configs="configs" :model="values['PolicyData'][childFieldName+'s'][childFieldName]" :fieldName="childFieldName" :field="childField" @repeatableRemoved="repeatableRemoved" @onInputUpdate="saveValues" :key="childFieldName"/>
                  </template>
                  <template v-else>
                    <FormGroup :configs="configs" :model="values[childFieldName+'s'][childFieldName]" :fieldName="childFieldName" :field="childField" @onInputUpdate="saveValues" :key="childFieldName"/>
                  </template>
            </template>
            <div class="col" :key="childFieldName" v-else>
              <FormulateInput 
                :type="getFieldType(childField.type)"
                :options="getFieldOptions(childField)"
                :validation="getFieldValidation(childField.validation)"
                :validation-messages="getFieldValidationMessages(childField.validationMessages)"
                :input-class="getInputClasses(childField)"
                :wrapper-class="getWrapperClass(childField)"
                :readonly="childField.readonly"
                :name="childFieldName"
                :placeholder="childField.placeholder"
                @change="saveValues(childField, values[index][childFieldName])"/>
            </div>
          </template>
      </div>
      </template>
    </FormulateInput>
  </div>
</template>
          
<script>
export default {
  name: 'FormGroup',
  components: {
  },
  props: {
    model: {
      type: Array,
      default() {
            return [];
      }
    },
    field: {
      type: Object,
      default() {
            return {};
      }
    },
    configs: {
      type: Object,
      default() {
            return {};
      }
    },
    fieldName: {
      type: String,
      default() {
            return "";
      }
    },
    allData: {
      type: Object,
      default() {
            return {};
      }
    },
    repIndex: {
      type: Number,
      default() {
            return null;
      }
    }
  },
  data: function () {
    return {
      values: [],
      forcedIndexes: {},
      toggles: {},
      filter: null,
      addCounter: 0,
      advancedMap: {}
    }
  },
  async created() {
    this.values = this.model;
    this.counter = this.model.length;
  },
  async mounted() {
    for (let j in this.values) {
      this.addCounter++;
      for (let i in this.field.inputs) {
        let f = this.field.inputs[i];
        if (true === f.readonly) {
          continue;
        }
        if (this.values[j][f.fieldName] && !this.field.advancedOptions) {
          this.values[j][f.fieldName].show = true;
        }
      }
    }
  },
  methods: {
    customAdd: function() {
      const par = this.$refs.vertGroup.getElementsByClassName('formulate-input-group-add-more');
      const btn = par[0].getElementsByTagName('button')[0];
      if (this.allData) {
          this.values.push({});
      } else {
        btn.click();
      }
    },
    getPrePostLabel: function(fieldName) {
      const val = this.configs.extras.labels[fieldName];
      return (val && val.label) ? val.label : false;
    },
    selectIndex: function(idx) {
      this.forcedIndexes[idx] = true;
      this.$forceUpdate(); //needed up to list :(
    },
    deselectIndex: function(idx, valObj, inputs) {
      for (let input in inputs) {
        if (!inputs[input].readonly) {
          valObj[input] = '';
        }
      }
      this.forcedIndexes[idx] = false;
      this.$emit("onInputUpdate", 'Repeatable', '');
      this.$forceUpdate(); //needed up to list :(
    },
    selectFilterMatch: function(row) {
      if (!this.filter) {
        return true;
      }
      
      const filt = this.filter.toLowerCase();

      for (let i in row) {
        let val = row[i].toString().toLowerCase().replace(/-/g, "").replace(/ /g, "").replace(/_/g, "");
        if (val.includes(filt)) {
          return true;
        }
      }

      return false;
    },
    hideRow: function(idx) {
      if (undefined === idx) {
        return true;
      }

      if (true === this.forcedIndexes[idx] || !this.field.selectable) {
        return false;
      }
      for (let fieldName in this.field.inputs) {
        let f = this.field.inputs[fieldName];
        if (true === f.readonly) {
          continue;
        }

        if (this.values[idx][fieldName]) {
          return false;
        }
      }

      return true;
    },
    customRemove: function(idx, event) {
      const par = this.findAncestor(event.target, 'formulate-input-group-repeatable');
      par.getElementsByClassName('vf-remove-btn')[0].click();
    },
    findAncestor: function(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    },
    saveValues: function (field, val) {
      //advanced options where choosing a summary select menu populates other fields
      if (field.advancedOptions && field.advancedOptions.targets) {
          const srcObj = this.advancedMap[val];
          for (let j in this.model) {
            if (undefined === this.values[j]['IndexKey'] || this.values[j]['IndexKey'] != val) {
              continue;
            }
            if ('' === val) {
              //wipe out dep fields
              for (let i in field.advancedOptions.targets) {
                const fn = field.advancedOptions.targets[i];
                delete this.values[j][fn];
              }
              break;
            }
            //populate dep fields
            for (let i in field.advancedOptions.targets) {
              const fn = field.advancedOptions.targets[i];
              this.values[j][fn] = srcObj[fn];
            }
          }
      }
      
      this.$emit("onInputUpdate", field, val);
    },
    repeatableRemoved: function () {
      if (this.field.autoId) {
        for (let i in this.values) {
          this.values[i][this.field.autoId] = parseInt(i)+1;
        }
      }
      this.$emit("repeatableRemoved", this.values, this.fieldName, this.field);
    },
    getFieldType: function(type) {
      return (type) ? type : "text";
    },
    getFieldOptions: function(field) {
      let options;
      if (field.advancedOptions) {
        let emptyLbl = field.advancedOptions.empty || '';
        options = [{"label": emptyLbl, "value": ''}];
        let src = field.advancedOptions.source;

        for (let i in this.allData[src+'s'][src]) {
          let fullLbl = '';
          for (let j in field.advancedOptions.values) {
              if (fullLbl) {
                fullLbl += ' - ';
              }
              const partLbl = this.allData[src+'s'][src][i][field.advancedOptions.values[j]];
              fullLbl += partLbl;
            }

            if (field.advancedOptions.targets) {
              this.advancedMap[i] = this.allData[src+'s'][src][i];
            }
            //options[i] = fullLbl;
            options.push({"label": fullLbl, "value": i});
        }
        //options[''] = emptyLbl;
      } else {
        options = (field.options) ? [''].concat(field.options) : field.options;
      }
      return (options) ? options : false;
    },
    getFieldValidation: function(valid) {
      return (valid) ? valid : false;
    },
    getFieldValidationMessages: function(msg) {
      return (msg) ? msg : {};
    },
    getHeadClass: function() {
      let base = 'row text-left group-col-head';
      return this.field.editable ? base : (base + ' repeat-static');
    },
    getInputClasses: function(field) {
      let base = (['radio', 'checkbox'].includes(field.type)) ? '' : 'form-control';
      if (field.classes) {
        base += " " + field.classes;
      }
      return base;
    },
    repeatableAdded: function(data) {
      if (this.field.autoId) {
        for (let i in data) {
          data[i][this.field.autoId] = parseInt(i)+1;
        }
      }
      this.forcedIndexes[data.length-1] = true;
      if ('vertical' != this.field.layout) {
        return;
      }
      const count = Object.keys(data).length;
      //hide others
      for (let i=0; i<count-1; i++) {
        this.toggleItem(this.fieldName, i, false);
      }
      this.toggleItem(this.fieldName, count-1, true);
    },
    toggleItem: function (fieldName, index, show) {
      const key = 'group-'+fieldName+'-'+index;
      const el = this.$refs[key];
      this.toggles[key] = show;
      if (!el) {
        return;
      }
      el.style.display = (show) ? 'block' : 'none';
      this.$forceUpdate(); //needed for button active conditions to work
    },
    isToggleActive: function (fieldName, index, show) {
      const key = 'group-'+fieldName+'-'+index;      
      return (false === show && false === this.toggles[key]) || (true === show && false !== this.toggles[key]);
    },
    getRemoveLabel: function(field) {
      return ('vertical' == field.layout) ? 'Remove' : 'x';
    },
    getWrapperClass: function(field) {
      return (['radio', 'checkbox'].includes(field.type)) ? 'form-check form-check-inline' : '';
    }
  }
}
</script>

<style lang="scss">
@import "bootstrap";
div.form-check-inline {
  div, input, label {
    display: inline;
  }
}

textarea.ta-ten {
  height: 200px !important;
}

.repeat-btn-remove {
  float: left;
}

.group-col-head {
  padding-left: 39px;
}

.formulate-input-group-add-more {
  button {
    @extend .btn, .btn-sm, .btn-secondary;
  }
  margin-top: 10px;
}

.repeat-static {
  padding-left: 0px;
}

.form-group-wrap div.row {
  margin-bottom: 10px;
}
table.table-add-existing {
  font-size: 12px;
}
</style>
