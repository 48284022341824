import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueFormulate from '@braid/vue-formulate';
import LogRocket from 'logrocket';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

//ie polyfill
Object.values = function (obj) {
    return Object.keys(obj).map(function (e) {
        return obj[e];
    });
};

// https://tc39.github.io/ecma262/#sec-array.prototype.findIndex
if (!Array.prototype.findIndex) {
    Object.defineProperty(Array.prototype, 'findIndex', {
      value: function(predicate) {
       // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }
  
        var o = Object(this);
  
        // 2. Let len be ? ToLength(? Get(O, "length")).
        var len = o.length >>> 0;
  
        // 3. If IsCallable(predicate) is false, throw a TypeError exception.
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }
  
        // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
        var thisArg = arguments[1];
  
        // 5. Let k be 0.
        var k = 0;
  
        // 6. Repeat, while k < len
        while (k < len) {
          // a. Let Pk be ! ToString(k).
          // b. Let kValue be ? Get(O, Pk).
          // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
          // d. If testResult is true, return k.
          var kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return k;
          }
          // e. Increase k by 1.
          k++;
        }
  
        // 7. Return -1.
        return -1;
      }
    });
  }

var VueScrollTo = require('vue-scrollto');
Vue.config.productionTip = false

Vue.use(VueFormulate, {
    classes: {
        outer: 'form-group',
        inputHasErrors: 'is-invalid',
        help: 'form-text text-muted',
        errors: 'list-unstyled text-danger',
        groupRepeatableRemove: 'btn btn-danger btn-sm repeat-btn-remove'
    }
});
Vue.use(VueScrollTo);
Vue.use(VueToast);
LogRocket.init('yt6ha5/forms');

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')