
module.exports = class CustomHooks{
    constructor() {
        
    }

    getDefault(fieldName, field, values) {
        if (fieldName && field && values) {
            return '';
            /*`- Terrorism coverage will be considered accepted and such coverage will be included without the signed and dated Disclosure Pursuant to Terrorism Risk Insurance Act notice prior to binding. Terrorism forms will be determined by the insured's acceptance or rejection. 
- At binding, submit a signed/dated original ACORD application.
- A satisfactory inspection report and compliance with loss control recommendations is required.`;*/
        } else {
            return '';
        }
    }
}