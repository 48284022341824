<template>
<div>
  <div id="head-controls" class="row fixed-top">
    <div class="col-md-8 text-center">
      <div class="progress">
        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" :style="'width: ' + progress +'%'"></div>
      </div>
      <span id="progress-msg" class="text-muted">{{ progressString }}</span>
    </div>
    <div class="col-md-2">
      <a @click="generatePolicy()" data-toggle="modal" data-target="#docsModal" :class="['btn btn-primary btn-block btn-sm', {'disabled': 100 != progress}]">
        <span v-if="inputConfig.context && true === inputConfig.context.end">Preview Policy Changes</span>
        <span v-else>Preview Policy Documents</span>
      </a>
    </div>
    <div class="col-md-2">
      <div class="dropdown">
        <button class="btn btn-sm btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="spinner-border spinner-border-sm mr-2" role="status" v-if="otherDocsLoading">
            <span class="sr-only">Loading...</span>
          </div>
          <span>Other Documents</span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#" @click.prevent="selectHiddenDoc(0)">Quote Document</a>
          <a class="dropdown-item" href="#" @click.prevent="selectHiddenDoc(1)">Binder Document</a>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex" id="wrapper">
    <div class="bg-light border" id="sidebar-wrapper">
      <FormsList :token="this.token" :inputConfig="inputConfig" ref="formsListRef" @onFormsListUpdate="onFormsListUpdate" @onActiveFormUpdate="onActiveFormUpdate" />
    </div>

    <div id="page-content-wrapper" v-show="formNumbers.length">

      <div v-if="formNumbers.length && 0 === totals().total">
        <div class="alert alert-success" role="alert">
          <h5>No additional data is required to populate the selected forms.</h5>
        </div>
      </div>
      
      <div id="input-wrapper" class="p-2 container-fluid" v-else>
        <div id="form-card" class="card text-center" v-show="formNumbers.length">
          <div class="card-header">
            <h6>{{ activeForm.FormName }}</h6>
            <h6 class="form-num" v-show="!isStaticCollectionForm(activeForm.FormID)">{{ activeForm.FormNumber }}</h6>
          </div>
          <div class="card-body">
            <div v-show="!getInputCount(activeForm)">
              <h6>No inputs are required for this form</h6>
            </div>
            <div>
              <FormWidget :configs="inputConfig" :issued="issued" :formNumbers="formNumbers" :activeForm="activeForm" :values="values" @onInputUpdate="saveValues" v-if="init"/>
            </div>
            <div v-if="activeForm.PolicyFormID">
              <button v-if="'Binder' == activeForm.FormID" @click="downloadDoc('binder', 'Binder.pdf')" class="btn btn-secondary btn-md">Download Binder</button>
              <button v-else-if="'QuoteLetter' == activeForm.FormID" @click="downloadDoc('quoteLetter', 'QuoteLetter.pdf')" class="btn btn-secondary btn-md">Download Quote Letter</button>
              <button v-else @click="previewForm()" class="btn btn-secondary btn-md">Preview Document</button>
              <iframe v-show="showPreview" id="docPreview" ref="docPreview" />
            </div>
          </div>
          <div class="card-footer text-muted">
              <div class="row">
                <div class="col-auto mr-auto">
                  <button type="button" class="btn btn-secondary btn-sm" @click="prevForm()" v-show="prevForm(true)">Previous</button>
                </div>
                <div class="col-auto">
                  <button type="button" class="btn btn-secondary btn-sm" @click="nextForm()" v-show="nextForm(true)">Next</button>
                </div>
              </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="modal fade" id="docsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center" v-if="true === loadPolicyDocs">
            <div class="spinner-border" role="status" style="width: 10rem; height: 10rem; margin: 200px; 0 20px">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="text-center text-muted" v-if="true === loadPolicyDocs">
            Generating Documents
          </div>
          <div v-show="!loadPolicyDocs">
              <iframe id="policyFrame" ref="policyFrame" @load="frameLoaded()"/>
              <a v-if="inputConfig.context && true === inputConfig.context.canBind && !complete && !issued" @click="issuePolicy()" class="btn btn-primary btn-sm">
                <span v-if="inputConfig.context && true === inputConfig.context.end">Bind Changes</span>
                <span v-else>Issue Policy</span>
              </a>
              <div v-else-if="inputConfig.context && false === inputConfig.context.canBind && true === inputConfig.context.bound" class="alert alert-warning" role="alert">
                This policy has been issued – all changes should be made by endorsement
              </div>
              <div v-else-if="inputConfig.context && false === inputConfig.context.canBind" class="alert alert-warning" role="alert">
                Policy must be bound before it can be issued
              </div>
            <div v-if="complete && !issued">
                <div class="d-flex justify-content-center" v-if="complete && !error && !issued">
                  <div class="spinner-border" role="status" style="width: 2rem; height: 2rem; margin: 20px; 0 20px">
                    <span class="sr-only">Issuing Policy...</span>
                  </div>
                </div>
                <h6 class="text-center">Issuing Policy...</h6>
            </div>
            <div id="issue-status">
              <div v-if="!complete && error" class="alert alert-danger" role="alert">
                Could not issue policy
              </div>
              <div v-if="issued" class="alert alert-success" role="alert">
                 <span v-if="inputConfig.context && true === inputConfig.context.end">
                   Policy updated successfully! You may now <button type="button" class="btn btn-outline-success btn-sm" @click="closeWindow()">close this window</button>
                 </span>
                 <span v-else>
                   Policy issued successfully! You may now <button type="button" class="btn btn-outline-success btn-sm" @click="closeWindow()">close this window</button>
                 </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import FormsList from "./FormsList.vue"; // @ is an alias to /src
import FormWidget from "./FormWidget.vue"; // @ is an alias to /src
import axios from "axios";
import Vue from "vue";

export default {
  name: 'Home',
  components: {
    FormsList,
    FormWidget
  },
  props: {
    token: {
      type: String
    }
  },
  data: function () {
    return {
      formNumbers: [],
      inputConfig: {},
      values: {},
      activeForm: {},
      showPreview: false,
      progress: 0,
      progressString: '',
      loadPolicyDocs: false,
      issued: false,
      error: false,
      complete: false,
      otherDocsLoading: false,
      init: false
    }
  },
  async created() {
    this.inputConfig = await this.getFieldData();
    this.values = await this.getUserData();
    this.updateProgress();
    this.init = true;
  },
  methods: {
    getUserData: async function () {
      let dat = {};
      await axios.get("/api/data/" + this.token).then(function(response) {
        dat = response.data;
      });
      return dat;
    },
    getFieldData: async function () {
      let dat = {};
      await axios.get("/api/fields/" + this.token).then(function(response) {
        dat = response.data;
      });
      return dat;
    },
    onFormsListUpdate: function (forms) {
      this.formNumbers = [];
      for (const form of forms) {
        if (form.Applied) {
          this.formNumbers.push(form.FormNumber);
        }
      }
      this.updateProgress();
    },
    onActiveFormUpdate: function(form) {
      this.activeForm = form;
      this.showPreview = false;
    },
    handlerChangeHooks: async function(chg) {
      if ('setIfEqual' == chg.type) {
        let ps = chg.params;
        for (let idx in ps) {
          const p = ps[idx];
          const target = p.target;
          const to = p.to;
          const conds = p.if;
          let allTrue = true;
          for (let i in conds) {
            for (let j in conds[i]) {
              if (this.values['PolicyData'][j] != conds[i][j]) {
                allTrue = false;
                break;
              }
            }
            if (!allTrue) {
              break;
            }
          }

          if (allTrue) {
            Vue.set(this.values['PolicyData'], target, to);
          }         
        }      
      }
    },
    saveValues: async function (field) {
      //locked during policy generation
      if (true === this.loadPolicyDocs) {
        return false;
      }
      this.updateProgress();

      if (field.change) {
        await this.handlerChangeHooks(field.change);
      }
      await axios.post("/api/data/" + this.token, this.values);
      
      //trigger reprint form
      if (this.activeForm && true !== this.activeForm.ReprintForm) {
        this.$refs.formsListRef.saveFormsList(this.activeForm, true);
      }
    },
    getInputCount: function (form) {
      if (!this.inputConfig.inputs) {
        return 0;
      }
      const obj = this.inputConfig.inputs[form.FormNumber];
      return obj ? Object.keys(obj).length : 0;
    },
    nextForm: function(dryRun) {
      if (!this.$refs.formsListRef) {
        return false;
      }
      return this.$refs.formsListRef.nextForm(dryRun);
    },
    prevForm: function(dryRun) {
      if (!this.$refs.formsListRef) {
        return false;
      }
      return this.$refs.formsListRef.prevForm(dryRun);
    },
    isStaticCollectionForm(formId) {
      return ['QuoteLetter', 'Binder'].includes(formId);
    },
    updateProgress: function() {
      const stat = this.totals();
      if (0 === stat.total) {
        this.progress = 100;
        this.progressString = "100% of inputs completed";
      } else {
        this.progress = stat.complete / stat.total * 100;
        this.progressString = stat.complete + " of " + stat.total + " inputs completed";
      }
    },
    totals: function() {
      const forms = this.$refs.formsListRef.getForms();
      let total = 0, complete = 0;
      for (let i in forms) {
        const thisForm = forms[i];
        if (thisForm.Applied) {
          const inputs = this.inputConfig.inputs[thisForm.FormNumber];
          for (let j in inputs) {
            if (false === inputs[j].required) {
              continue;
            }
            if (inputs[j].type != 'group') {
              total++;
              if (this.values['PolicyData'] && (this.values['PolicyData'][j] || 0 === this.values['PolicyData'][j])) {
                complete++;
              }
            } else {
              //@todo handle group logic
              //for now just handling as a single input that's complete
              complete++;
              total++;
            }
          }
        }
      }

      return { complete: complete, total: total };
    },
    issuePolicy: async function() {
      let dat = {};
      this.complete = true;
      this.error = false;
      if (this.inputConfig.context && true === this.inputConfig.context.end) {
        await axios.get("/api/bind/" + this.token).then(response => {
          dat = response.data;
          if (dat.result) {
            this.issued = true;
            this.error = false;
          } else {
            this.error = true;
            this.complete = false;
          }
        }).catch(err => {
          console.log(err);
          this.error = true;
          this.complete = false;
        });
      } else {
        await axios.get("/api/issue/" + this.token).then(response => {
          dat = response.data;
          if (dat.result) {
            this.issued = true;
            this.error = false;
          } else {
            this.error = true;
            this.complete = false;
          }
        }).catch(err => {
          console.log(err);
          this.error = true;
          this.complete = false;
        });
      }
      return dat;
    },
    previewForm: function() {
      if (true === this.showPreview) {
        return this.showPreview = false;
      }
      this.$refs.docPreview.src = '/api/template/' + this.activeForm.PolicyFormID + '/' + this.token;
      this.showPreview = true;
    },
    generatePolicy: function() {
      this.loadPolicyDocs = true;
      setTimeout(this.frameLoaded, 15000);
      this.$refs.policyFrame.src = "/api/docs/" + this.token;
      this.showPreview = false;
    },
    selectHiddenDoc: function(idx) {
      const forms = this.$refs.formsListRef.getForms();
      this.$refs.formsListRef.selectForm(forms[idx], false, true);
    },
    downloadDoc: async function(type, name) {
      this.otherDocsLoading = true;
      const url = '/api/' + type + '/' + this.token;
      await axios({
            url: url, 
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
          this.$toast.open({ 
            message: 'Quote must be bound before generating binder',
            type: 'error',
            dismissible: true,
          });
        });
        this.otherDocsLoading = false;
    },
    frameLoaded: function() {
      this.loadPolicyDocs = false;
    },
    closeWindow: function() {
      window.open('','_self').close();
    }
  }
}
</script>

<style lang="scss">
#wrapper {
  overflow-x: hidden;
  margin-top: 80px;
}

#sidebar-wrapper {
  min-height: 100vh;
  width: 50%;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  border-top: 1px solid rgba(0,0,0,.125);
  padding: 0px
}

#sidebar-wrapper .list-group {
  width: 100%;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.nowrap {
  white-space: nowrap;
}

#docPreview {
  width: 100%;
  min-height: 600px;
  margin-top: 20px;
}

#forms-list-wrap {
  font-size: 11px;
  input {
    margin-top: 0px;
  }
}

#btn-return {
  margin-bottom: 2em;
}

div.form-group {
  text-align: left;
}

#issue-status {
  margin-top: 10px;
}

h6.form-num {
  font-size: .85em;
}

div#sidebar-wrapper {
  overflow-y: scroll;
  height: 800px;
}

#head-controls {
  div.progress {
    margin-top: 10px;
  }
  margin: 0px;
  padding: 15px 20px 0 20px;
  height: 70px;
  background-color: #fff;
}
#input-wrapper {
  padding-top: 0px !important;
}
#progress-msg {
  font-size: 13px;
}
#policyFrame, #docsModal .modal-content {
  width: 100%;
  min-height: 800px;
}
</style>
