<template>
  <Index :token="this.token" />
</template>

<script>
import Index from '../components/Index.vue';
export default {
  name: 'Home',
  components: {
    Index
  },
  props: {
    token: {
      type: String
    }
  }
}
</script>