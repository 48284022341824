<template>
    <form>
      <fieldset :disabled="issued">
        <template v-for="(inputs, formNumber) in this.configs.inputs">
          <div v-if="formNumber == activeForm.FormNumber" :key="formNumber">
            <template v-for="(field, fieldName) in inputs">
                  <template v-if="'group' == field.type">
                    <template v-if="'policy' == field.parent">
                      <FormGroup :configs="configs" :allData="values" :model="values['PolicyData'][fieldName+'s'][fieldName]" :fieldName="fieldName" :field="field" @repeatableRemoved="repeatableRemoved" @onInputUpdate="saveValues" :key="fieldName"/>
                    </template>
                    <template v-else>
                      <FormGroup :configs="configs" :allData="values" :model="values[fieldName+'s'][fieldName]" :fieldName="fieldName" :field="field" @onInputUpdate="saveValues" :key="fieldName"/>
                    </template>
                  </template>
                  <template v-else>
                    <div :key="fieldName">
                      <h5 v-if="getPrePostLabel(fieldName)" class="p-3">{{ getPrePostLabel(fieldName) }}</h5>
                      <div class="row">
                        <label class="col-sm-4 col-form-label" v-bind:class="{ required: isRequired(field) }">{{ field.label }}</label>
                        <div class="col-sm-8">
                          <FormulateInput 
                            :type="getFieldType(field.type)"
                            :options="getFieldOptions(field.options)"
                            :validation="getFieldValidation(field.validation)"
                            :validation-messages="getFieldValidationMessages(field.validationMessages)"
                            :input-class="getInputClasses(field, fieldName)"
                            :wrapper-class="getWrapperClass(field)"
                            v-model="values['PolicyData'][fieldName]"
                            :disabled="isDisabled(field)"
                            @change="saveValues(field, values['PolicyData'][fieldName])" />
                        </div>
                      </div>
                    </div>
                  </template>
            </template>
          </div>
        </template>
      </fieldset>
    </form>
</template>
          
<script>
import FormGroup from "./FormGroup.vue"; // @ is an alias to /src
let CustomHooks = require('../Util/CustomHooks.js');
CustomHooks = new CustomHooks();
export default {
  name: 'FormWidget',
  components: {
    FormGroup
  },
  props: {
    configs: {
      type: Object,
      default() {
            return {}
      }
    },
    activeForm: {
      type: Object,
      default() {
            return {}
      }
    },
    values: {
      type: Object,
      default() {
            return {}
      }
    },
    issued: {
      type: Boolean,
      default() {
            return false;
      }
    },
    formNumbers: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data: function () {
    return {

    }
  },
  methods: {
    saveValues: function (field, val) {
      this.$emit("onInputUpdate", field, val);
    },
    getFieldType: function(type) {
      return (type) ? type : "text";
    },
    getFieldOptions: function(options) {
      return (options) ? options : false;
    },
    getFieldValidation: function(valid) {
      return (valid) ? valid : false;
    },
    getFieldValidationMessages: function(msg) {
      return (msg) ? msg : {};
    },
    isRequired(field) {
      return false !== field.required;
    },
    getInputClasses: function(field, fieldName) {
      //hook for calculating default value
      this.getDefault(fieldName, field);
      
      let base = (['radio', 'checkbox'].includes(field.type)) ? '' : 'form-control';
      if (field.classes) {
        base += " " + field.classes;
      }
      return base;
    },
    getWrapperClass: function(field) {
      return (['radio', 'checkbox'].includes(field.type)) ? 'form-check form-check-inline' : '';
    },
    isDisabled: function(field) {
      return true === field.disabled;
    },
    getFormInputs: function(formNum) {
      return this.configs.inputs[formNum];
    },
    hasForm: function (forms) {
      if (0 == forms.length) {
        return true;
      }
      for (const i in forms) {
        if (this.formNumbers.includes(forms[i])) {
          return true;
        }
      }

      return false;
    },
    getPrePostLabel: function(fieldName) {
      const val = this.configs.extras.labels[fieldName];
      return (val && val.label) ? val.label : false;
    },
    repeatableRemoved: function (vals, fieldName, field) {
      if ('policy' == field.parent) {
        this.values['PolicyData'][fieldName+'s'][fieldName] = vals;
      } else {
        this.values[fieldName+'s'][fieldName] = vals;
      }
      this.saveValues(field, vals);
    },
    getDefault(fieldName, field) {
      if ('calculate' == field.default) {
        if (this.values['PolicyData'][fieldName]) {
          return false;
        }
        const val = CustomHooks.getDefault(fieldName, field, this.values);
        return this.values['PolicyData'][fieldName] = val;
      } else if (field.default && undefined === this.values['PolicyData'][fieldName]) {
        return this.values['PolicyData'][fieldName] = field.default;
      }

      return false;
    }
  }
}
</script>

<style lang="scss">
div.form-check-inline {
  div, input, label {
    display: inline;
  }
}
label.required:after {
  content:"*";
  color:red;
  margin-left: 5px;
}
textarea.tah-200 {
  min-height: 400px !important;
  font-size: 12px;
}
</style>
