<template>
  <div class="bg-light mt-0 px-2 pt-0 mt-0 list-group list-group-flush" id="forms-list-wrap">
    <div class="row mt-4" v-if="!this.forms.length">
      <div class="col-md-8 mx-auto">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="border-bottom-1 border-dark bg-light p-2 pt-3 sticky-filter input-group mb-2">
        <span class="input-group-prepend">
            <div class="input-group-text bg-transparent border-right-0"><i class="fa fa-search"></i></div>
        </span>
        <input type="search" id="fl-filter" v-model="filter" placeholder="Search" class="form-control">
      </div>
      <div class="container text-center sticky-buttons">
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-sm btn-outline-secondary" :class="{ active: 'all' == filterScope }" @click="filterScope='all'">All</button>
              <button type="button" class="btn btn-sm btn-outline-secondary" :class="{ active: 'selected' == filterScope }" @click="filterScope='selected'">Selected</button>
              <button type="button" class="btn btn-sm btn-outline-secondary" :class="{ active: 'required' == filterScope }" @click="filterScope='required'">Required</button>
              <button type="button" class="btn btn-sm btn-outline-secondary" :class="{ active: 'optional' == filterScope }" @click="filterScope='optional'">Optional</button>
            </div>
        </div>
      <table class="font-weight-bold table table-striped">
        <tr v-bind:class="{ selected: form.FormNumber == activeForm.FormNumber }"
          v-for="(form, i) in this.forms" :key="i" v-show="filterMatch(form) && !isStaticCollectionForm(form.FormID)">
          <td class="align-middle pb-4" style="padding-left: 6px; padding-right: 6px">
            <a :id="'form' + form.FormID"></a>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-on:click="saveFormsList(form)"
                v-model="form.Applied"
                :disabled="isDisabled(form)"
              />
            </div>
          </td>
          <td class="align-middle px-0" v-if="inputConfig.context && true === inputConfig.context.end">
            <a href="#" @click.prevent="toggleReprint(form)" v-show="form.Applied" title="Reprint Form"><i class="fas fa-print mr-2" v-bind:class="{ 'text-muted': !form.ReprintForm }"></i></a>
          </td>
          <td class="align-middle px-0">
            <span v-if="getInputCount(form)" style="font-size: 1.1em" class="badge badge-pill badge-success">{{ getInputCount(form) }}</span>
          </td>
          <td class="formName" @click="selectForm(form, false, true)">
            <a :href="getTemplateUrl(form.PolicyFormID)" target="_blank">
              {{ form.FormNumber }}
            </a><br/>
              
            {{ form.FormName }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'FormsList',
  props: {
    token: {
      type: String
    },
    inputConfig: {
      type: Object,
      default() {
            return {}
      }
    }
  },
  emits: ["onFormsListUpdate"],
  data: function () {
    return {
      forms: [],
      activeForm: {},
      lastForm: {},
      filter: "",
      filterScope: "all"
    }
  },
  async created() {
    this.forms = await this.getFormsList();
    this.emitForms();

    //init form selection
    //skip static collection forms (quote letter, binder) if needed
    const firstIdx = (this.isStaticCollectionForm(this.forms[0].FormID)) ? 2 : 0;
    this.selectForm(this.forms[firstIdx]);
    if (!this.getInputCount(this.forms[firstIdx])) {
      this.nextForm();
    }
  },
  methods: {
    getFormsList: async function () {
      let formsList;
      await axios.get("/api/list/" + this.token).then(function(response) {
        formsList = response.data.forms;
      });

      return formsList;
    },
    isDisabled: function(form){
      if (true === form.UserSelected && this.inputConfig.context && true === this.inputConfig.context.end) {
        return false;
      } 
      return form.Mandatory;
    },
    filterMatch: function (form) {
      const scopeCond = ("all" == this.filterScope 
        || ("selected" == this.filterScope && true === form.Applied)
        || ("required" == this.filterScope && true === form.Mandatory)
        || ("optional" == this.filterScope && false === form.Mandatory)
      );

      if (!this.filter) {
        return scopeCond;
      }
      
      const filt = this.filter.toLowerCase();
      const filtNum = filt.replace(/-/g, "").replace(/ /g, "").replace(/_/g, "");

      return (scopeCond && (form.FormNumber.toLowerCase().replace(/-/g, "").replace(/ /g, "").replace(/_/g, "").includes(filtNum)
        || form.FormName.toLowerCase().includes(filt)));
    },
    selectForm: function (form, scrollTo, disableToggle) {
      this.activeForm = form;
      this.$emit("onActiveFormUpdate", this.activeForm);
      if (this.activeForm && !form.Applied && !disableToggle) {
        form.Applied = false;
        this.saveFormsList(form);
      }

      var options = {
        container: "#sidebar-wrapper",
        easing: 'ease-in',
        lazy: false,
        offset: -120,
        force: true,
        cancelable: true,
        x: false,
        y: true
      };
    
      if (false !== scrollTo) {
        this.$scrollTo("#form" + form.FormID, 600, options);
      }
    },
    getTemplateUrl: function (templateId) {
      return false === templateId
      ? "#"
      : "/api/template/" + templateId + "/" + this.token;
    },
    getInputCount: function (form) {
      const obj = this.inputConfig.inputs[form.FormNumber];
      return obj ? Object.keys(obj).length : 0;
    },
    saveFormsList: function (form, reprintOnly, reprintVal) {
        if (!reprintOnly) {
          if (!form.Applied) {
            form.Applied = true;
            form.Waived = false;
            form.ReprintForm = true;
            this.selectForm(form, false);
          } else {
            form.Applied = false;
            form.Waived = true;
            form.ReprintForm = false;
          }
          form.UserSelected = true;
          this.emitForms();
        } else {
          if (false !== reprintVal) {
            reprintVal = true;
          }
          form.ReprintForm = reprintVal;
          form.UserSelected = reprintVal;
        }

        axios.put("/api/list/" + this.token, this.forms);
    },
    getForms: function() {
      return this.forms;
    },
    emitForms: function () {
        this.$emit("onFormsListUpdate", this.forms);
    },
    nextForm: function(dryRun) {
      if (!this.activeForm) {
        return;
      }
      
      let next;
      for (let i in this.forms) {
        const thisForm = this.forms[i];
        //skip static quote letter and binder collection pages
        if (this.isStaticCollectionForm(thisForm.FormID)) {
          continue;
        }
        if (this.activeForm.FormNumber == thisForm.FormNumber) {
          next = true;
          continue;
        }

        if (next && thisForm.Applied && 0 < this.getInputCount(thisForm)) {
          if (dryRun) {
            return thisForm;
          }
          this.selectForm(thisForm);
          return true;
        }
      }
    },
    toggleReprint(form) {
      const rp = Boolean(form.ReprintForm);
      form.ReprintForm = !rp;
      form.UserSelected = true;
      axios.put("/api/list/" + this.token, this.forms);
    },
    isStaticCollectionForm(formId) {
      return ['QuoteLetter', 'Binder'].includes(formId);
    },
    prevForm: function(dryRun) {
      if (!this.activeForm) {
        return;
      }
      
      let next;
      for (let i=this.forms.length-1; i >=0; i--) {
        const thisForm = this.forms[i];
        if (this.activeForm.FormNumber == thisForm.FormNumber) {
          next = true;
          continue;
        }

        if (next && thisForm.Applied && this.getInputCount(thisForm)) {
          if (dryRun) {
            return thisForm;
          }
          this.selectForm(thisForm);
          return true;
        }
      }
    }
  }
}
</script>

<style lang="scss">
td.formName {
  text-align: left;
}
.spinner-border {
  width: 3rem;
  height: 3rem;
}
tr {
  cursor: pointer;
}
tr.selected {
  background-color: #edf6ff;
}
tr.selected:after {
  border-bottom: 10px solid transparent;
  border-left: 10px solid #007bff;
  border-top: 10px solid transparent;
  margin-top: 10px;
  margin-right: 5px;
  height: 0;
  width: 0;
  float:right;
  content: '';
}
.sticky-filter {
  position: -webkit-sticky !important; /* safari */
  position: sticky !important;
  top: 0px;
  z-index: 200;
}
.sticky-buttons {
  position: -webkit-sticky !important; /* safari */
  position: sticky !important;
  top: 60px;
  z-index: 200;
  background-color: #f8f9fa !important;
  padding-bottom: 15px;
}
#fl-filter:focus {
    outline: 0 !important;
    border-color: none;
    box-shadow: none;
}
</style>
